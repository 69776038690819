<template>
  <div class="mt-2 d-flex flex-column align-items-center justify-content-center">
    <!-- search Box -->
    <b-card no-body class="px-2" style="width:100%; max-width: 800px;">
      <b-card-body>
        <b-input-group>
          <b-form-textarea
            v-model="input"
            class="searchInput"
            placeholder="Please Enter Tracking Number or Order Number"
            rows="5"
            max-rows="10"
            no-resize
          />
          <b-input-group-prepend
            is-text
            class="ll-searchBtn"
            @click="searchById"
            >
            <feather-icon
              icon="SearchIcon"
              class="mr-25"
              size="18"
            />
          </b-input-group-prepend>
        </b-input-group>
        
      </b-card-body>
    </b-card>
    <div class="row" style="width:100%; max-width: 800px;margin-bottom:10px;display:flex;flex-direction:row-reverse">
        <b-button 
          variant="info" @click="langsChang">
           <span :class="enLangs==false?'Lgs-t':'lgs-f'">中</span> / <span :class="enLangs==true?'Lgs-t':'lgs-f'">EN</span>
        </b-button>
    </div>
    <b-card no-body style="width:100%; max-width: 800px;" v-for="(v, i) in data" :key="i">
      <!-- <template #header class="ll-headerBox">
        <h3 class="mb-0" v-if="v.trackingNumber">TN # : {{v.trackingNumber}}</h3>
        <h3 class="mb-0" v-else>Order # : {{v.orderNumber}}</h3>
        <h3 class="mb-0 ml-1">Third Party # : {{v.kuaidiRefNum}}</h3>
      </template> -->
      <b-card-header class="card-header ll-headerBox">
        <h3 class="mb-0" v-if="v.trackingNumber">TN # : {{v.trackingNumber}}</h3>
        <h3 class="mb-0" v-else-if="v.orderNumber">Order # : {{v.orderNumber}}</h3>
        <h3 class="mb-0 ml-1" v-if="v.kuaidiRefNum">Third Party # : {{v.kuaidiRefNum}}</h3>
      </b-card-header>
      <b-card-body class="px-3" v-if="v.status">
        <app-timeline>
          <app-timeline-item v-for="(s, j) in v.status" :key="j"
            :title="getTitle(s.cnDescription,s.statusDesc)"
            :subtitle="s.description"
            :time="s.dateTime.slice(0, -3)"
            :variant="getVariant(s)"
          />
        </app-timeline>
      </b-card-body>
      <b-card-body class="px-3" v-else>
        <b-alert
          variant="danger"
          show
        >
          <div class="alert-body">
            <span>Order is not found</span>
          </div>
        </b-alert>
      </b-card-body>
      
    </b-card>
    <b-card no-body style="width:100%; max-width: 800px" v-for="(v, i) in data" :key="i">
      <div v-if="v.signature != null">
        <div v-for="(item,index) in v.signature" :key="index">
          <b-img
            :src="item"
            style="width:100%;"
            alt=""
          />
        </div>
      </div>
    </b-card>
    
    
  </div>
</template>

<script>

import {
  BCard, BCardBody, BFormTextarea,
  BFormCheckbox,
  BFormGroup,
  BBreadcrumb,
  BBreadcrumbItem,
  BBadge,BButton,
  BRow, BCol,
  BAlert,BImg,
  BInputGroupPrepend, BInputGroup, BCardHeader,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BButton,
    BImg,
    BBreadcrumb,
    BBreadcrumbItem,
    BBadge,
    BAlert,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BInputGroup,
    BFormGroup,
    AppTimeline,
    AppTimelineItem,
    ToastificationContent,
    BCardHeader,
  },
  data() {
    return {
      input:"",
      data:[],
      enLangs:true
    }
  },
  computed: {},
  created() {
  },
  mounted() {
    if (this.$route.query.q) {
      this.input = this.$route.query.q.split(",").join("\n")
      // this.code = this.$route.query.code
      this.searchById()
    }
  },
  methods: {
    searchById() {
      let i = this.input.split("\n");
      if (i.length > 10) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Maximun search 10 orders, your input is " + i.length + " orders",
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return;
      }
      let toSearch = [];
      let isError = false;
      for (let j = 0; j < i.length; j++) {
        if (i[j].trim().length != 0) {
          toSearch.push(i[j].trim());
        }
        // if (i[j].trim().length < 8 && i[j].trim().length != 0) {
        //   this.$toast({
        //     component: ToastificationContent,
        //     props: {
        //       title: "'" + i[j].trim() + "' is invalid input, it must be at least 8 characters or digits",
        //       icon: 'XIcon',
        //       variant: 'danger',
        //     },
        //   })
        //   isError = true
        // }
      }
      if (toSearch.length == 0) {
        this.$toast({
            component: ToastificationContent,
            props: {
              title: "Please input Tracking Number or Order Number to search",
              icon: 'InfoIcon',
              variant: 'info',
            },
          })
        isError = true
      }
      if (isError == false) {
        this.query(toSearch.join(","))
      }
      this.input = toSearch.join("\n")
    },
    query(query) {
      this.data = []
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Loading, please wait",
          icon: 'InfoIcon',
          variant: 'info',
        },
      })
      axios.defaults.timeout = 20000
      let code = this.$route.query.code?this.$route.query.code:''
      this.$http.get('/lmd/trackAndTrace?q=' + query+'&code='+code).then(res => {
        if (res.data.data == null) {
          this.data = []
        } else {
          this.data = res.data.data
        }
      }).catch(error => console.log(error))
    },
    getVariant(s) {
      if (s.code == "ORDER_RECEIVED") {
        return "info"
      } else if (s.stage == "COMP") {
        return "primary"
      } else if (s.type == "EXCEPTION") {
        return "danger"
      } else if (s.type == "NORMAL") {
        return "success"
      }
      return "secondary"
    },
    // getTitle(str,cn) {
    //   if(this.enLangs == true){
    //     var separateWord = str.toLowerCase().split('_')
    //     for (var i = 0; i < separateWord.length; i++) {
    //         separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1)
    //     }
    //     return separateWord.join(' ')
    //   }else{
    //     return cn
    //   }
      
    // },
    getTitle(cnDesc,enDesc){
      if(this.enLangs == true){return enDesc}else{return cnDesc}
    },
    langsChang(){
      this.enLangs = this.enLangs?false:true
    },
  },
}
</script>
<style scoped>
.ll-searchBtn div{
  background-color: #ff9f43;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ll-headerBox{
  justify-content: flex-start;
}
.Lgs-t{
  font-size: 16px;
  font-weight: bold;
}
.Lgs-f{
  font-size: 12px;
}
</style>
